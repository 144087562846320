.login-navbar {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ff0000;
}
.login-navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1270px;
  gap: 726px;
}

.login-navbar-logo {
  cursor: pointer;  
  display: flex;
}

.sign-up-link {
  text-decoration: none;
}

.sign-up-links {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.sign-up-links h3 {
  width: 139px;
  height: 20px;    
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 120%;    
  color: #FFFFFE;                    
}

.login-sign-up-btn {
  padding: 11px 0px;  
  width: 95px;
  height: 42px;  
  background: #FF0000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border: none;
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 120%;
  color: #FFFFFE;
}

@media screen and (max-width: 768px) {
  .login-navbar-container {
    width: 100vw;
    gap: 87px; 
  }
  
  .login-navbar-logo {
    margin-left: -24px;
}
  
  .sign-up-links > h3 {
    display: none;
                      
  }

  .login-sign-up-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

}

@media only screen and (min-width: 412px) and (max-width: 414px) {
  .login-navbar-container {
      gap: 134px;
}
}


