.live-tournaments-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  padding-top: 20px;
  padding-bottom: 30px;
  background: #1D1E22;
  
}

.join-tournament-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 56px;  
  height: auto;
  margin: 0 auto;
}

.join-tournament-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 10px;  
  height: 121px;
}

.join-tournament-heading > h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 53px;
    letter-spacing: 4.44px;
    text-transform: uppercase;    
    color: #FF0000;    
    mix-blend-mode: normal;
    opacity: 0.8;
}

.live_parag {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 1.5px;    
    color: #F8F3F3;
}

  .join-tournament-container>.list.-four {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

  .join-tournament-container>.list {
    display: grid;
    grid-gap: 24px 20px;
    grid-template-columns: 1fr;
    padding: 0;
  }

  .search-result-card {
    background-color: #303540;
    border-radius: 4px;
    width: 250px;
  }

  .search-result-card>.header>img {
    height: 100px;
    width: 100%;
    object-fit: cover;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }

  .search-result-card>.body {
    height: calc(100% - 100px);
    padding: 16px 16px calc(42px + 24px);
    position: relative;
  }

  .user-details {
    display: flex;
    align-items: center;
    position: relative;
  }

  .user-details>.img {
    flex: 0 0 auto;
    overflow: hidden;
    border-radius: 2px;
  }

  .user-avatar.-smed {
    height: 40px;
    width: 40px;
  }

  .user-details>.name.-break, .user-details>.block>.deet.-break {
    word-break: break-word;
  }
  .user-details>.img+.name, .user-details>.img+.block {
    margin-left: 14px;
  }

  .user-details>.name {
    margin-right: 8px;
    color: #fff;
  }

  b, strong {
    font-weight: bold;
  }

  .list-details {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .list-details>li:not(:last-child) {
    margin-bottom: 8px;
  }

  .list-details>li>.icon {
    width: 15px;
    text-align: center;
    margin-right: 8px;
  }

  .list-details>li>.icon, .list-details>li>.text {
    color: #9F9EA8;
  }

  .search-result-card>.body>.actions {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 16px;
  }

  .search-result-card>.body>.actions>.btn {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .form-control, .btn:not(.btn-link,.btn-outline-default,.btn-transparent,.btn-icon) {
    -moz-box-shadow: 0 1px 1px 0 rgba(0,0,0,0.3);
    -webkit-box-shadow: 0 1px 1px 0 rgba(0,0,0,0.3);
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.3);
  }

  .btn {
    text-transform: uppercase;
    border-radius: 3px;
    font-weight: 700;
    letter-spacing: 0.04em;
  }

  .btn-block {
    display: block;
    width: 100%;
  }

  .btn-default {
    color: #fff;
    background-color: #ff0000;
    border-color: #ff0000;
  }

  .btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    border-radius: 2px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  a {
    color: #FF7324;
    text-decoration: none;
  }

  a {
    background-color: transparent;
  }

  .no-tournaments {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
  }


  @media screen and (max-width:768px){

    .join-tournament-container>.list.-two, .join-tournament-container>.list.-three, .join-tournament-container>.list.-four, .join-tournament-container>.list.-four.-lg-colgap {
      justify-items: center;
      grid-template-columns: 1fr;
  }

  .join-tournament-heading {
    height: 170px;
}

  .join-tournament-heading > h1 {
    width: 350px;
    font-size: 30px;
    line-height: 37px;
    text-align: center;
  }

  .live_parag {
    /* width: 350px; */
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    text-align: start;
    text-transform: none;
  }

  .search-result-card {
    width: 270px;
}
    
  }




