    .sidebar {
        position: absolute;
        width: 100px;
        height: 116vh;
        left: 0px;
        top: 0px;        
        background: #1D1E22;
        box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.3);
    }

    .logo {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 10px;
        
        position: absolute;
        width: 100px;
        height: 100px;
        left: 0px;
        top: 0px;
    }

    .dash-navbar-logo > img {
    width: 100px;
    height: 100px;
    }

    .sidebar-nav {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0px;
        gap: 23px;        
        position: absolute;
        width: 70px;
        height: 200px;
        left: 15px;
        top: 368px;        
        background: #1D1E22;
    }

    .sidebar-nav > ul > li {
        width: 50px;
        height: 50px;   
        list-style: none;     
    }

    .icon {
        width: 24px;
        height: 24px;
        color: white;
      }
      
      .sidebar-nav .active .icon {
        background-size: 200px;
        border-radius: 20px;
        background-color: red; /* Set your desired active icon background color */
      }
    
@media screen and (max-width: 768px) {
    .sidebar {
        height: 1170px;
    }
        
}
