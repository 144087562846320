
.hero-container {
  height: 869px;

  }

  .main-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 45px 88px;
    gap: 10px;
    height: 869px;

    background-image: url('../../public/images/main-section-img.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  }

  .main-section-container {
    height: 779px;
  }

.main-section-container > h1 {
  width: 801px;
  height: 154px;
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 120%;  
  color: #FFFFFE;
}

.main-section-container > p {
  width: 548px;
  margin-left: 625px;
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  color: #A7A9BE;
}

.hero-btns {
  margin-top: 25px;
  margin-left: 625px; 
  
}

.hero-btns .btn {
  margin: 6px;
}

.tournaments-btns {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  background: transparent;
  width: 19rem;
  height: auto;
}

.tournaments-btns .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #FF0000;
  border-radius: 4px;
}

.tournaments-btns .arrow_icon {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

.tournaments-btns .arrow_icon::before {
  position: absolute;
  content: "";
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
}

.tournaments-btns .button_text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  inset: 0;
  padding: 6px;
  color: #fff;
  font-size: 24px;
  line-height: 1.5;
  letter-spacing: 1px;
  margin-right: -46px;

}

.tournaments-btns:hover .circle {
  width: 100%;
}

.tournaments-btns:hover .arrow_icon {
  background: #fff;
  transform: translate(1rem, 0);
}


.fixtures {
  margin-top: -70px;
}

@media screen and (max-width: 768px) {
  .hero-container {
    height: 370px;  
    
    }

    .main-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 31px 23px;
      gap: 29px;
      
      height: 370px;
      background-color: black;
      background-image: none;
    }
  
    .main-section-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      gap: 30px;
      width: 316px;
      margin-top: 14px;

    }
  
  .main-section-container > h1 {
    width: 355px;    
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 120%;
    text-align: center;
    color: #FFFFFF;
  }
  
  .main-section-container > p {
    width: 355px;
    height: 80px;
    margin-left: 0px;
    margin-top: -60px;
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    color: #FFFFFF;
    text-align: center;
  }

  .hero-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;
    margin-left: 2px;
    margin-top: 0px;
  }

  .tournaments-btns .button_text {
    font-size: 23px;
    margin-right: -31px;
}

  .fixtures {
    display: none;
  }
  

}

