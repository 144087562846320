.payment-hero-section {
    background-color: black;
    height: 100vh;
}

.payment-hero-title {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    /* height: 100vh; */
    padding-top: 50px;
}

.payment-title-para {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
}

.payment-title-para > h1 {
    width: 1034px;    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 80px;  
    letter-spacing: 4px;
    color: white;
    text-align: center;
}

.payment-title-para > p{
    width: 600px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 36px;
    color: #FFFFFF;
    text-align: center;
}

.payment-qr-para {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 30px;
    gap: 10px;
}

.payment-qr-para > p{
    width: 600px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 36px;
    color: #FFFFFF;
    text-align: center;
}
.mpesa {
    gap: 10px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.mpesa > h3 {
    color: red;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;

}

.qr-bck {
    background-color: white;
}

.qr {
    height: 250px;
    width: 250px;
}

.thank-hero-section {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    height: 50vh;
}