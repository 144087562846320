.product-demo-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
    padding-top: 50px;
    background: #0F0E17;
}

.product-demo-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}

.product-demo-items > img {
    width: 600px;
    height: 400px;
    border-radius: 4px;
}

.product-demo-items > h2 {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 120%;
    text-align: center;
    color: #FFFFFE;
}

  @media screen and (max-width: 768px) {

    .product-demo-container {
        display: flex;
        justify-content: center;
        padding: 0px;
        padding-bottom: 30px;
    }
    
    .product-demo-items {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }

    .pubg-video {
        width: 100vw;
        border-radius: 4px;
}

    .product-demo-items > h2 {
        font-family: 'PT Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 120%;
        text-align: center;
        color: #FFFFFE;
        padding-left: 30px;
        padding-right: 30px;
    }

    .btn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        height: 44px;
        background: #FF0000;
    }

    .btn-link {
        text-decoration: none;
    }
  }