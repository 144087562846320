.featured-games-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 36px 196px;
  gap: 10px;
  height: 470px;
  background: #1A1A1A;
}

.games-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 29px;
    width: 1047px;
    height: 398px;
}

.games-header{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 14px;    
    width: 417px;
    height: 70px;
    
}

.red-bar {
  width:22px;
  height: 70px;
  background: #B42323;
}

.games-header > h2 {
    width: 459px;
    height: 53px;    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 53px;    
    letter-spacing: 4.44px;
    text-transform: uppercase;    
    color: #FF0000;    
    mix-blend-mode: normal;
    opacity: 0.8;
}

.games-type-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 61px;    
    width: 1047px;
    height: 300px;
    
}

.games-type-section {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 61px;    
    width: 812px;
    height: 300px;
    
}

.games-type-item {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.games-type-item > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.all-games-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 3px;    
    width: 174px;
    height: 32px;
    text-decoration: none;
    
}

.all-games-container > h4 {
    width: 139px;
    height: 21px;    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 32px;    
    color: #A72828;
}

.call-of-duty-games-section {
    transition: transform 0.3s ease-in-out;
  }
  
  .call-of-duty-games-section.zoomed {
    transform: scale(1.1);
  }

  .nba-games-section {
    transition: transform 0.3s ease-in-out;
  }
  
  .nba-games-section.zoomed {
    transform: scale(1.1);
  }

  .tekken-games-section {
    transition: transform 0.3s ease-in-out;
  }
  
  .tekken-games-section.zoomed {
    transform: scale(1.1);
  }
  

  @media screen and (max-width: 768px) {
    .featured-games-section {
      width: 100vw;
      height: 360px;
      padding: 0;
  }
  .games-section {
    width: 100%;
    height: 266px;
    align-items: center;
  }
  .games-header {
    width: 350px;
  }
  .red-bar {
    width: 19px;
    height: 55px;
  }
  
  .games-header > h2 {
    width: 313px;
    font-size: 28px;
    line-height: 53px;
}

  .games-type-container {
    flex-direction: column;
    width: 340px;
    height: 181px;
    gap: 10px;
  }

  .games-type-section {
    flex-direction: row;
    width: 340px;
    gap: 5px;
    height: 143px;
}

  .all-games-container {
    margin-left: 10px;
}

.games-type-item {
  height: 140px;
  width: 110px;
  list-style: none;
}

.call-of-duty-games-section {
  height: 140px;
}

.nba-games-section {
  height: 140px;
}

.tekken-games-section {
  height: 140px;
}


}