.about-us-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 50;
    padding-right: 50px;
    background-color: #FFFFFE;
}

.about-us-items-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
}

.about-us-items-title >h2 {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    color: #0F0E17;
    width: 60%;
    text-align: center;
}

.about-us-items-list {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; 
}

.items > img {
    width: 80px;
    height: 80px;
}

.items-desc {
    max-width: 250px;
    box-sizing: border-box;
    text-align: center; 
}

.items-desc > h3 {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    color: #0F0E17;
    text-align: center;
}

.items-desc > p {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 120%;
    color: #2E2F3E;
    text-align: center;
    margin-top: 10px;
}
.items-left {
    margin-left: 50px;
}


@media screen and (max-width: 768px) {

    .about-us-container {
        padding: 20px;
    }

    .about-us-items-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 30px;
    }

    .about-us-items-title > h2 {
        font-family: 'PT Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
        text-align: center;        
        color: #0F0E17;
        width: 100%;
    }

    .about-us-items-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .items {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;        
    }

    .items > img {
        width: 100px;
        height: 100px;
    }

    .items-desc {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .items-desc > h3 {  
        font-family: 'PT Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 120%;
        color: #0F0E17;
    }

    .items-desc > p {   
        font-family: 'PT Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 120%;
        color: #2E2F3E;
    }

    .items-left {
        margin-left: 0px;
    }
}