.login-body {
    height: 927px;
}
.login-main-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 45px 88px;
    gap: 10px;
    height: 927px;
    background-image: url('../../../public/images/2096366.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.login-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: 460px;
    height: 700px;
}

.login-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 21px;    
    width: 439px;
}

.login-title > h1 {
    width: 407px;    
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 120%;    
    color: #FFFFFE;
}

.login-title > p {
    width: 427px;
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    color: #FFFFFE;    
}

.social-media-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 26px;    
    width: 439px;
    height: 84px;    
}

.social-media-login > h3 {
    width: 110px;    
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%; 
    color: #FFFFFE;    
}

.login-social-icon {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;    
    width: 439px;
    height: 34px;  
}

.login-form-or-line {
    border: none;
    color: #FFFFFE;
    overflow: visible;
    text-align: center;
    width: 439px;
    border-top: 3px solid #FFFFFE;
    margin-left: 0px; 
    margin-bottom: 10px;
}

.form-or-line:after {
    background: #0F0E17;
    content: 'or';
    padding: 0 4px;
}


.login-user-box input {
    width: 439px;
    height: 50px;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    margin-bottom: 30px;
    background: #64646B;
    border: none;
}

.login-user-box ::placeholder {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 420;
    font-size: 20px;
    line-height: 120%;
    color: #FFFFFE;
    background: none;
}


.rmb-frgt {
    display: flex;
    width: 439px;
    height: 24px;
    gap: 59px;
}

.forget-password {
    width: 142px;
    height: 24px;
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 120%;    
    text-decoration-line: underline;    
    color: #FF8906;    
}

.login-form-line {
    width: 439px;
    height: 0px;
    border: 1px solid #FFFFFE;
    margin-left: 0px; 
    margin-top: 40px;
}

.login-btn {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 120%;
    color: #FFFFFE;
    text-transform: uppercase;
    letter-spacing: 4px;
    cursor: pointer;
    padding: 11px 30px;
    width: 439px;    
    background: #FF0000;
    border-radius: 4px;
    border: none;
    margin-top: 18px;
}

.login-box form button:hover {
    box-shadow: 0 0 15px #03e9f4;
}

.error-message {
    font-family: 'PT Sans', sans-serif;
    color: #fff;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
  }
  

@media screen and (max-width: 768px) {
    .login-body {
        height: 690px;
    }
    .login-main-section {
        background-position: left;
        display: flex;
        align-items: center;
        height: 690px;
    }

    .login-box {
        width: auto;
    }

    .login-title {
        align-items: center;
        gap: 12px;
        width: auto;
    }

    .login-title h1 {
        width: 344px;
        font-size: 32px;
        text-align: center;
    }

    .login-title p {
        width: 318px;
        font-size: 18px;
        text-align: center;
    }

    .social-media-login {
        width: 0;
    }
    .login-social-icon {
        width: 321px;
    }

    .login-social-icon > a > img {
        width: 100px;
    }

    .login-form-or-line {
        width: 318px;                       
    }

    .login-user-box input {
        width: 297px;
        padding: 13px 20px;
    }

    .login-form-line {
        width: 318px;
    }

    .login-btn {
        display: block;
        width: 318px;
        margin-top: 24px;
    }

    .rmb-frgt {
        width: 292px;
        gap: 37px;
    }

    .forget-password {
        font-size: 14px;
    }

}