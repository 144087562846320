.how-it-works-container {
    background: #0F0E17;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-right: 80px;
    padding-left: 80px;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  padding: 0px; 
  gap: 30px;
}

.how-it-works-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px;
  gap: 21px;

}

.how-it-works-title > h2 {
  font-family: 'PT Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 54px;
  line-height: 120%;    
  color: #FFFFFE;
}

.how-it-works-title > p{
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: #A7A9BE;
  width: 80%;
}

.how-it-works-title > hr {
  width: 95px;
  height: 10px;
  background: #FF0000;
  flex: none;
  flex-grow: 0;
  border: none;
}

.how-it-works {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 67px;
}

.how-it-works-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
}

.how-it-works-items > h3 {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: #FF0000;
}

.how-it-works-items > p {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #FFFFFE;
}

.img-works {
  height: 490px;
}



  @media screen and (max-width: 768px) {

    .how-it-works-container {
      background: #000000;
      padding: 20px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }
    
    .img-works {
      display: none;
    }

    .content-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;
      padding-bottom: 20px;
    }

    .how-it-works-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 20px;      
    }

    .how-it-works-title > h2 {
      font-family: 'PT Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 120%;      
      color: #FFFFFE;
    }

    .how-it-works-title > p {
      font-family: 'PT Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 120%;      
      color: #A7A9BE;
    }

    .how-it-works-title > hr {
      width: 85px;
      height: 6px;
      
      background: #FF0000;
    }

    .how-it-works {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 20px;  
    }

    .how-it-works-items {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 15px;      
    }

    .how-it-works-items > h3 {
      font-family: 'PT Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 120%;
      color: #FF0000;
    }

    .how-it-works-items > p {
      font-family: 'PT Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 120%;   
      color: #FFFFFF;
    }

  }
  

