.signup-body {
    height: 927px;
}
.signup-main-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 45px 88px;
    gap: 10px;
    height: 927px;
    background-image: url('../../../public/images/2096366.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.signup-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: 460px;
    height: 700px;
}

.signup-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 21px;    
    width: 439px;
}

.signup-title > h1 {
    width: 430px;    
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 120%;    
    color: #FFFFFE;
}

.signup-title > p {
    width: 400px;
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    color: #FFFFFE;    
    text-align: center;
}

.social-media-signup {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 26px;    
    width: 439px;
    height: 84px;    
}

.social-media-signup > h3 {
    width: 110px;    
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%; 
    color: #FFFFFE;    
}

.signup-social-icon {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;    
    width: 439px;
    height: 34px;  
}

.signup-form-or-line {
    border: none;
    color: #FFFFFE;
    overflow: visible;
    text-align: center;
    width: 439px;
    border-top: 3px solid #FFFFFE;
    margin-left: 0px; 
    margin-bottom: 10px;
}

.signup-form-or-line:after {
    background: #0F0E17;
    content: 'or';
    padding: 0 4px;
    position: relative;
    top: -13px;
}

.terms-policy-container {
    width: 439px;
    height: 24px;
}

.newsletter-container {
    width: 450px;
    height: 24px;
    margin-top: 5px;
}

.terms-privacy-checkbox {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #A7A9BE;
    margin-left: 5px;  
}

.newsletter-opt-in {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #A7A9BE;
    margin-left: 5px;  
}

.terms-policy {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;    
    text-decoration-line: underline;    
    color: #FF8906;    
}

.signup-user-box input {
    width: 439px;
    padding: 15px;
    font-size: 16px;
    color: #fff;
    margin-bottom: 30px;
    background: #64646B;
    border: none;
}

.signup-user-box ::placeholder {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 420;
    font-size: 20px;
    line-height: 120%;
    color: #FFFFFE;
    background: none;
}

.pass-form-line {
    width: 439px;
    height: 0px;
    border: 1px solid #FFFFFE;
    margin-left: 0px; 
    margin-top: 10px;
}

.signup-form-line {
    width: 439px;
    height: 0px;
    border: 1px solid #FFFFFE;
    margin-left: 0px; 
    margin-top: 40px;
}

.signup-btn {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 120%;
    color: #FFFFFE;
    text-transform: uppercase;
    letter-spacing: 4px;
    cursor: pointer;
    padding: 11px 30px;
    width: 439px;    
    background: #FF0000;
    border-radius: 4px;
    border: none;
    margin-top: 18px;
}

.signup-box form button:hover {
    box-shadow: 0 0 15px #03e9f4;
}

select {
    appearance: none;
    background-color:#64646B;
    font-size: 16px;
    padding: 8px;
    width: 439px;
    color: white;
  }
  
  select:focus {
    border-color: #0077cc;
    box-shadow: 0 0 0 2px #0077cc33;
    outline: none;
  }
  
  select option {
    background-color: #fff;
    color: #333;
  }

  .icon-input {
    display: flex;
    align-items: center;
  }
  
  .icon-input > svg {
    margin-right: 8px;
    font-size: 18px;
  }


  @media screen and (max-width: 768px) {
    .signup-body {
        height: 970px;
    }
    .signup-main-section {
        background-position: left;
        display: flex;
        align-items: center;
        height: 970px;
    }

    .signup-box {
        width: auto;
        height: fit-content;
        gap: 25px;
    }

    .signup-title {
        align-items: center;
        gap: 12px;
        width: auto;
    }

    .signup-title h1 {
        width: 344px;
        font-size: 32px;
        text-align: center;
    }

    .signup-title p {
        width: 318px;
        font-size: 18px;
        text-align: center;
    }

    .social-media-signup {
        width: 0;
    }
    .signup-social-icon {
        width: 321px;
    }

    .signup-social-icon > a > img {
        width: 100px;
    }

    .signup-form-or-line {
        width: 318px;                       
    }

    .signup-user-box input {
        width: 297px;
        padding: 13px 20px;
    }

    select {
        padding: 13px;
        width: 297px;
    }

    .pass-form-line {
        width: 318px;
        margin-top: 14px;
    }
    form {
        display: flex;
        flex-direction: column;
        margin-top: -20px;
        align-items: center;
        width: 318px;
    }

    .terms-policy-container {
        width: 309px;
        margin-top: 16px;
    }

    .newsletter-container {
        width: 306px;
        margin-top: 5px;
    }

    .newsletter-opt-in {
        margin-left: 13px;
    }

    .signup-form-line {
        width: 318px;
    }

    .signup-btn {
        display: block;
        width: 318px;
        margin-top: 24px;
    }

  }
                         
  