.main {
    background: url('https://wallpaperaccess.com/full/1329677.jpg'); 
}

.content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 34px 55px;
    gap: 10px;
    height: 975px;
}

.content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 57px;
}

@media screen and (max-width: 768px) {
    .content-wrapper {
        width: 100vw;
        padding: 25px;
        height: fit-content;
    }
    
}
