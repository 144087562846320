:root {
  --primary: #FF0000;
  
}

.btn-home {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: var(--primary);
  border-radius: 4px;
  padding: 11px 36px;
  font-family: 'PT Sans';
  color: #FFFFFE;
  font-style: normal;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  gap: 10px;
  height: 42px;

}
.btn--no--outline {
  background-color: transparent;
  border-radius: 4px;
  padding: 11px 36px;
  font-family: 'PT Sans';
  color: #FFFFFE;
  font-style: normal;
  text-decoration-line: underline;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 11px 23px;
  border-radius: 4px;
  border: 1px solid red;
  transition: all 0.3s ease-out;
  justify-content: center;
  font-style: normal;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--cta {
  font-size: 24px;
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  color: #242424;
  transition: 250ms;
}
