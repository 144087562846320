.dropdown-menu {
    position: absolute;
    top: 84px;
    list-style: none;
    text-align: start;

    width: 320px;
    
    background: #3D3C3C;
  }
  
  .dropdown-menu li {
    cursor: pointer;
  }

  .title-desc {
    gap: 3px;    
    width: 239px;
  }

  .play-online-tournaments {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;    
  }
  
  .dropdown-menu li:hover {
    background: #5cabff;
  }

  .title {
    
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;    
    color: #FFFFFE;
  }

  .desc {    
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    
    color: #FFFFFE;
  }
  
  .dropdown-menu.clicked {
    display: none;
  }
  
  .dropdown-link {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: #fff;
    padding: 16px;
  }
  
  @media screen and (max-width: 960px) {
    .fa-caret-down {
      display: none;
    }
  }