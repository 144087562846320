.sign-up-navbar {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #ff0000;
  }
  .sign-up-navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1270px;
    gap: 726px;
  }
  
  .sign-up-navbar-logo {
    color: #fff;  
    cursor: pointer;  
    display: flex;
  }
  .login-links {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  
  .login-links h3 {
    width: 190px;
    height: 20px;    
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 120%;    
    color: #FFFFFE;                    
  }

  @media only screen and (min-width: 414px) and (max-width: 414px) {
    .sign-up-navbar-container {
        gap: 140px;
}
  }

  
  @media screen and (max-width: 768px) {
    .sign-up-navbar-container {
      width: 100vw;
      gap: 112px; 
    }
    
    .sign-up-navbar-logo {
      color: #fff;  
      cursor: pointer;  
      display: flex;
    }
    
    .login-links h3 {
      display: none;                  
    }

    .login-links > button {
      display: block;
    }
  }