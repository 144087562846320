.cards {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 50px 88px 60px;
  gap: 10px;
  height: 632px;  
  background: #FFFFFE;
  }
  .top-games-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 30px;    
    width: 1146px;    
  }

  .games-title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 27px;    
    width: 1146px;
    height: 92px;
  }
  
  .games-title-container >h1 {
    width: 250px;
    height: 65px;    
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 54px;
    line-height: 120%;     
    color: #0F0E17;
   
  }
  
  .games-title-container > hr {
    width: 1146px;
    height: 0px;    
    border: 1px solid #000000;
  }
  
  .cards__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;    
    width: 1090px;
    height: 400px;
  }
  
  .cards__wrapper {  
   
    flex: none;
    order: 1;
    flex-grow: 0;
  
    position: relative;
    margin: 20px 0 45px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
  }
  
  .cards__items {
    margin-bottom: 24px;
    gap: 5px;
    width: 1090px;
   
  }
  
  .cards__item {
    display: flex;
    flex: 1;
    margin: 0 0.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    position: relative;
  
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 50%;                              
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .cards__item__pic-wrap::after {
    display: flex;
    flex-direction: row;
    content: attr(data-category);
    position: absolute;
    top: 0;
    padding: 10px 10px;
    font-size: 12px;
    width: 100px;
    height: 20px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    justify-content: center;
    background-color: #1f98f4;
  }
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 340px;
    max-width: 100%;
    height: 180px;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 10px 20px 20px;
    font-family: 'Poppins';
  
  }
  
  .cards__item__text {
    color: black;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
  }
  
  @media only screen and (max-width: 768px) {
    .cards {
      padding-top: 20px;
      padding-left: 20px;
      height: 1230px;
    }

    .top-games-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 19px;
      width: 331px;
    }

    .games-title-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 11px;
      
      width: 331px;
      height: 47px;
    }
    .games-title-container >h1 {
      width: 138px;
      
      font-family: 'PT Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 120%;
    }
  
    .games-title-container hr {
      width: 331px;
      height: 0px;      
      border: 1px solid #000000;
    }

    .cards__container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 16px;      
      width: 331px;    
    }

    .cards__items {
      display: block;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 15px;
      
      width: 331px;
      height: 528px;
    }
  }
  
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }


