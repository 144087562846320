.home-navbar {
  background: #0F0E17;
  height: 85px;
  border-bottom: 1px solid #ff0000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-navbar-container {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  padding: 0px;
  gap: 201px;
  height: 42px;
  width: 1213px;
}

.home-navbar-logo {
  width: 162px;
  height: 42px;
  cursor: pointer;
  display: flex;
}

.home-nav-menu {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 35px;
  
  width: 370px;
  height: 90px;
  list-style: none;
}

.home-nav-item {
  height: 90px;  
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
}

.home-nav-links {  
  color: #FFFFFE;
  text-decoration: none;
  /* padding: 0.5rem 1rem; */
  align-items: center;
  display: flex;
  height: 100%;
  gap:10px;
}

.home-nav-links:hover {
  border-bottom: 4px solid #ff0000;
  transition: all 0.2s ease-out;
}

.home-sign-up-login-btn {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  
  height: 42px;
}

.fa-bars {
  color: #fff;
}

.home-nav-links-mobile {
  display: none;
}

.home-menu-icon {
  display: none;
}

/* .home-sign-up-login-btn > Link {
  text-decoration: none;
} */

.home-sign-up-btn {
  padding: 11px 36px;  
  width: 190px;
  height: 45px;  
  background: #FF0000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border: none;

  /* text */

  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 120%;
  color: #FFFFFE;
}

.home-login-btn {
  box-sizing: border-box;  
  align-items: center;
  padding: 11px 23px;  
  width: 87px;
  height: 42px;  
  border: 1px solid #F25F4C;
  border-radius: 4px;
  background: none;

  /* Text */
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 120%;
  color: #FFFFFE;
}

.home-login-btn:hover {
  background: #fff;
  color: black;
  border: none;
  border-color: none;
}

.user_dashboard {
  margin-left: 230px;
}


.profilepic {
  width: 30px;
}

/* For screen with max-width 768px */

@media screen and (max-width: 768px)  {
  .mobile__signup__login {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .home-navbar {
    margin: 0;
    padding: 0;
  }

  .home-navbar-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: start;
    padding: 0px;
    gap: 170px;
    width: 100vw;
  }

  .home-navbar-logo {
    width: 140px;
  }  

  .home-menu-icon {
    display: block;
    font-size: 1.8rem;
    cursor: pointer;
    width: 40px;
  }

  .home-nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 64px;
    left: -115%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .home-nav-menu.active {
    display: flex;
    align-items: center;
    padding-top: 30px;
    margin-top: 21px;
    background: #0F0E17;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .home-nav-item {
    height: 60px;
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
}

  .home-nav-links {
    padding: 2rem;
    display: table;
    text-transform: uppercase;
  }

  .home-nav-links:hover {
    background-color: #ff0000;
    border-radius: 0;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .home-nav-links-mobile {
    display: block;
    text-align: center;
    padding: 17px 30px;
    border-radius: 4px;
    background: #ff0000;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
    width: 260px;
    text-transform: uppercase;
  }

  .home-nav-links-mobile:hover {
    background: #fff;
    color: #ff0000;
    transition: 250ms;
  }

  .home-sign-up-login-btn {
    display: none;
  }
  .user_dashboard {
    display: none;
  }

  button {
    display: none;
  } 
  .profilepic {
    width: 30px;
  }
}

@media only screen and (min-width: 412px) and (max-width: 414px) {
  .home-navbar-container {
    width: 388px;
    gap: 205px;
  }

  .profilepic {
    width: 30px;
  }

}
