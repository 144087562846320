.news-hero-section {
    background-color: black;
    height: 100vh;
}

.news-hero-title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
}

.title-para {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
}

.title-para > h1 {
    width: 1034px;    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 80px;  
    letter-spacing: 4px;
    color: white;
    text-align: center;
}

.title-para > p{
    width: 600px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 36px;
    color: #FFFFFF;
    text-align: center;
}

@media screen and (max-width: 768px) {   
    .tournaments-main-logo {
        width: 260px;
        height: 60px;
        margin-bottom: 10px;
    }
    
    .news-logo{
        width: 260px;
    }
    .title-para {
        gap: 10px;
    }

    .title-para > h1 {
        width: 341px;    
        font-size: 30px; 
        line-height: 37px;
        text-align: center;
        }
    
    .title-para > p {
        width: 339px;
        font-weight: 500;
        font-size: 19px;
        line-height: 22px;
        text-align: center;
        text-transform: none;
    }

    .host-tournaments-btn {
        display: block;
    }
}