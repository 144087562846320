.browse__games__section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 19px;
    padding-left: 150px;
    padding-top: 0px;
}

.title__header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 488px;
}

.games__title {
    display: flex;
    align-items: center;
    gap: 14px;
}

.games__title > h3 {
    color: #FFF;
    font-family: Poppins;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.arrow-right {
    color: red;
    width: 18px;
  }
  
  .view__all {
    display: flex;
    align-items: center;
    gap: 14px;
  }

  .view__all > h4 {
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .view__all__mobile {
    display: none;
  }

  .view__all__mobile {
    display: none;
  }

  .games__section {
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }

  .games__section > li {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  .games__section > li > p {
    color: #FFF;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

@media screen and (max-width: 768px) {
  .browse__games__section {
    padding-left: 112px;
  }

  .games__title {
    gap: 6px;
  }

  .games__title > h3 {
    width: 176px;
    font-size: 18px;
  }

  .games__section {
    flex-direction: column;
  }

  .games__section > li > img {
    width: 65vw;
  }

  .view__all__link {
    display: none;
  }

  .view__all{
    display: none;
  }

  .view__all__mobile {
    display: flex;
    align-items: center;
    gap: 14px;
  }

  .view__all__mobile > h4 {
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  
}