.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  height: 400px;
}

.footer-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 35px;
  height: 284px;  
  background-color: black;
}

.footer-info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 109px;  
}

.web-desc {
  width: 289px;
  height: 109px;
}

.web-desc-img {
  margin-left: -18px;
}

.footer-logo {
  width: 116px;
  height: 34px;
}

.web-desc > p {
  width: 282px;
  height: 58px;
  
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  
  color: #FFFFFE;
}

.footer-links {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 100px;
}

.footer-link-items-about {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 11px;  
  width: 106px;
}

.footer-link-items-about > li> h2 {
  width: 77px;  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #FFFFFE; 
}

.footer-link-items-contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 11px;  
  width: 285px;  
}

.footer-link-items-contact > li> h2 {
  width: 96px;  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #FFFFFE; 
}

.footer-link-items-about > li {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 11px;
  gap: 3px;
  width: 106px;
}

.footer-link-items-contact > li {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 11px;
  gap: 3px;  
  width: 106px;
}

.footer-link-items-about a {
  width: 200px;
  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 186.3%;  
  color: #A7A9BE;
  text-decoration: none;
}

.footer-link-items-about a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-link-items-contact a {
  width: 200px;  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 186.3%;  
  color: #A7A9BE;
  text-decoration: none;
}

.footer-link-items-contact a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-link-items-contact > li > p {
  width: 279px;
  height: 48px;  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;  
  color: #A7A9BE; 
}


.footer-socials-docs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 18px;  
  width: 1000px;
  height: 45px;
}

.footer-socials-docs > hr {
  width: 1000px;
  height: 0px;
  margin-left: 0px;  
  border: 1px solid #D2D2D2;
}

.footer-ending {
  display: flex;
  flex-direction: row;  
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 639px;  
  width: 997px;
  height: 27px;
  margin-top: 10px;
}

.docs > {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 3px;
  
  width: 244px;
  height: 27px;
}
.website-rights {
  color: #fff;
  width: 40px;
}

.docs a {
  width: 200px;  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 186.3%;  
  color: #A7A9BE;
  text-decoration: none;
}

.docs a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 24px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {

  .footer-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 30px;
    height: 561px;
    margin-top: 159px;
    padding-top: 36px;
    background-color: black;
  }

  .footer-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 30px;    
    height: 430px;
  }

  .web-desc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 17px;
    height: 109px;
    margin-left: 20px;
  }

  .web-desc > p {
    width: 282px;    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;    
    color: #FFFFFE;
  }

  .footer-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 30px;
    height: 312px;
    margin-left: 21px;
  }

  .footer-link-items-about {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 14px;
    height: 138px;
  }

  .footer-link-items-about > li {
    padding: 0;
  }

  .footer-link-items-about > li > h2 {
    width: 77px;
    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;    
    color: #FFFFFE;
  }

  .footer-link-items-contact {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;    
    width: 279px;  

  }

  .footer-link-items-contact > li {
    padding: 0;
  }
  
  .footer-link-items-contact > li > h2 {
    width: 95px;
    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    color: #FFFFFE;
  }

  .footer-link-items-contact > li > p {
    width: 279px;    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;    
    color: #A7A9BE;
  }
  
  .footer-link-items-contact > li > a {
    width: 233px;    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #A7A9BE;
  }

  .footer-socials-docs {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 18px;
    padding-bottom: 25px;    
    width: 100vw;
    height: 53px;
  }

  .footer-socials-docs > hr {
    width: 90vw;
  }

  .footer-ending {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 15px;    
    width: 244px;
    margin-left: 17px;
  }
}
