.dashboard-container {
    padding-left: 50px;

}

h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 45px;
    color: #FFFFFF;
}

.joined_title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 45px;
    color: #FFFFFF; 
    margin-top: 40px;
    margin-bottom: 40px;
}

.sub_title {
    font-size: 25px;
}

.game-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.text__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
}

.text__wrapper > p {
    width: 416px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 120%;
    color: #FFFFFF;
}

/* Responsiveness for laptops (width >= 1280px) */
@media (min-width: 1280px) {

    .joined__tournament__container {
        width: 60%;
        height: auto;
        padding: 30px;
    }

    h2, .joined_title {
        font-size: 40px;
        line-height: 50px;
    }

    .sub_title {
        font-size: 28px;
    }

    .text__wrapper > p {
        width: 500px;
        font-size: 18px;
    }

    .view__link {
        width: 150px;
        height: 50px;
        font-size: 18px;
    }
}

/* Responsiveness for mobile screens (width <= 768px) */
@media (max-width: 768px) {
    .dashboard-container {
        padding: 20px;
    }

    .joined__tournament__container {
        width: 100%;
        height: auto;
        padding: 15px;
        border-radius: 20px;
    }

    h2, .joined_title {
        font-size: 24px;
        line-height: 30px;
    }

    .sub_title {
        font-size: 18px;
    }

    .game-text-container {
        gap: 10px;
    }

    .new {
        width: 30px;
        height: 18px;
    }

    .new > p {
        font-size: 8px;
    }

    .text__wrapper > p {
        width: 100%;
        font-size: 14px;
    }

    .view__link {
        width: 120px;
        height: 40px;
        font-size: 14px;
    }
}
