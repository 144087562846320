.tournament-container{        
  background-image: url('../../../../public/images/create-tournament-bckg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
  padding: 50px;
  }

  .tournament-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 7px; 
  }

    .section-one-title {
      font-family: 'Bebas Neue';
      font-style: normal;
      font-weight: 400;
      font-size: 64px;
      line-height: 77px;
      text-align: center;
      letter-spacing: 4px;        
      color: #FFFFFF;
    } 

    .tournaments-form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .tournament-form-box{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 0px;
        gap: 31px;
    }

    label {
        width: 179px;
        height: 27px;        
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;        
        letter-spacing: -0.32px;        
        color: #FFFFFF;
      }

      ::placeholder {
        color: white;
        font-family: 'Poppins';        
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        background-color: #313131;
      }

      .form-input-box {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px;     
        width: 290px;        
        background: #313131;        
        border: 1px solid #313131;
        box-shadow: 0px 0px 0px #CBDAFC;
        border-radius: 4px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;            
        color: #FFF;
      }


      input:focus {
        outline: none;
      }

      .host-icon {
        width: 24px;
        position: absolute;
        height: 24px;
        color: white;
        left: 258px;
        top: -30px;
    }

      .default-url {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 5px;
        position: absolute;
        gap: 90px;        
        height: 45px;      
        background: #434544;
        border-radius: 4px 0px 0px 4px;        
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;        
        color: #7C7C7C;
      }

      .desc-form {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 0px;
        
        width: 494px;
        height: 173px;
      }

      textarea:focus {
        outline: none;
      }

      .games-icon {
        position: absolute;
        width: 24px;
        height: 24px;
        margin-left: 258px;
        margin-top: -30px;
        color: white;
      }

      .typetournamnet-icon{
        width: 24px;
        position: absolute;
        height: 24px;
        color: white;
        margin-left: 258px;
        margin-top: -30px;
      }

      .tournament-type-desc {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        padding-top: 10px;
        gap: 10px;        
        width: 273px;
      }

      .tournament-type-desc > p {
        width: 273px;        
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.32px;        
        color: #FFFFFF;
        
      }

      .custom-file-upload {
        display: inline-block;
        padding: 6px 12px;
        cursor: pointer;
        background-color: #4caf50;
        color: white;
        border-radius: 4px;
        border: none;
        transition: background-color 0.3s ease;
      }
      
      .custom-file-upload:hover {
        background-color: #45a049;
      }
      

      .tournament-image-upload {
        height: 100px;

      }

      .tournament-type-box{
        padding-top: 56px;
      }

      .tournament-format-box{
        padding-top: 100px;
      }

      .tournamnet-format-icon{
        position: absolute;
        width: 24px;
        height: 24px;
        margin-left: 258px;
        margin-top: -30px;
        color: white;
      }

      .save-continue-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px 60px;
        gap: 10px;        
        width: 494px;
        height: 48px;
        border: none;
        margin-top: 20px;
        
        background: #FF0000;

        /* Button Text */         
        font-family: 'Bebas Neue';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 18px; 
        letter-spacing: 2px;        
        color: #000000;
      }
      
      .participants-emails {
        margin-top: 40px;
        width: 484px;
        height: 100px;
      }

      .radio-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 20px;
        
        width: 260px;
        
      }
      
      .radio-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 14px 50px;
        gap: 10px;
        width: 100px;
        height: 46px;
        background: #313131;
        border-radius: 4px;
        color: white;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
        cursor: pointer;
        
      }
      
      .radio-button.active {
        background: #ff0000;        
      }
      
      .registration-fee{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 0px;
        gap: 27px;        
        width: 494px;
        margin-top: 40px;
      }

      .reg-fee{
        width: 140px;
        height: 27px;        
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;        
        letter-spacing: -0.32px;        
        color: #FFFFFF;
      }

      .paid-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 0px;        
        width: 290px;
        height: 230px;
        
      }

      .free-paid-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 9px;        
        width: 290px;
      }

      .reg-fee-div{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 10px;        
        width: 140px;
      }

      .location-wrapper {
        margin-top: 22px;
      }

      .location-container {
        height: 50px;
      }

      /* .location-div {
        width: 187px;
      }

      .location {
        width: 187px;
      } */

      .participants-cap-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 0px;
        gap: 88px;
        
        width: 399px;
        margin-top: 25px;
      }

      .participants-cap-title{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 10px;
        
        width: 104px;
      }

      .participants-cap-title > label {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        
        letter-spacing: -0.32px;
        
        color: #FFFFFF;
      }

      .participants-cap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 15px;
        
        width: 197px;
        
      }

      .cap-checkbox {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 10px;
        
        width: 197px;
      }

      .check-box {
        padding: 2px;
        gap: 10px;
        
        width: 20px;
        height: 20px;
      }
      
      input[type="checkbox"]:checked {
        background-color: red;
      }
      
      input[type="checkbox"]::before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        vertical-align: middle;
        background-color: red;
      }
      
      input[type="checkbox"]:checked::before {
        content: "✔";
        color: white;
      }
      

      .participants-number-label {
        width: 167px;
        height: 46px;
        
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: -0.32px;
        
        /* Grey Text Inputs */
        
        color: white;
      }

      .cap-box-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 10px;
        
        width: 174px;
        height: 47px;
      }

      .cap-box-container > label {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        
        letter-spacing: -0.32px;
        
        color: white;
      }

      .cap-box {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 10px;
        background: #313131;        
        border: 1px solid #313131;
        box-shadow: 0px 0px 0px #CBDAFC;
        border-radius: 4px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;        
        letter-spacing: -0.32px;        
        color: white;
        gap: 90px;
        
        width: 50px;
        height: 47px;
      }

      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }      

      .start-time-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 0px;
        gap: 8px;
        
        width: 494px;
        margin-top: 25px;
      }

      .form-date-time{
        gap: 87px;
      }
      
      .previous-submit-btn {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 77px;
        
        width: 473px;
        margin-top: 30px;
      }

      .section-two-btn{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px 8px;
        gap: 10px;        
        width: 232px;
        height: 48px;        
        background: #FF0000;
        border: none;
        
        /* Button Text */
        font-family: 'Bebas Neue';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
        /* identical to box height, or 100% */
        letter-spacing: 2px;
        text-decoration: none;
        
        color: #000000;
      }

      .previous-btn {
        width: 165px;
      }

  @media only screen and (max-width: 768px) {
        .tournament-container {
          /* width: 100%; */
          padding-left: 10px;
        }

        .tournament-box {
          width: 325px;
          margin-right: 18px;
          padding-bottom: 50px;
        }

        .section-one-title {
          font-size: 46px;
          width: auto; 
          display: inline-block; 
        }    

        .tournaments-form {
          /* width: 314px; */
          height: fit-content;
          gap: 36px;
        }

        .tournaments-name{
          padding-bottom: 6px;

        }

        .tournament-form-box {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 10px 0px;
          gap: 12px;
      }

      .desc-form {
        padding-bottom: 160px;
        width: 100%;
      }

      .host-icon {
        display: block;
      }

      .games-icon {
        display: block;
      }

      .tournament-type-box {
        padding-bottom: 106px;

      }

      .typetournamnet-icon {
        display: block;
      }

      .tournamnet-format-icon {
        display: block;
      }

      .save-continue-btn {
        width: 290px;
        height: 50px;
        margin-top: 22px;
    }

      .participants-emails {
        margin-top: 10px;
    }

      .registration-fee {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0px;
        width: 314px;
        margin-top: 157px;
    }

      .paid-container {
        gap: 0;
    }

    .participants-cap-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
      width: 308px;
      margin-top: 16px;
    }

    .start-time-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      width: 308px;
      margin-top: 6px;
  }

    .previous-submit-btn {
      display: flex;
      justify-content: center;
      gap: 9px;
      align-items: center;
      width: 331px;
      margin-left: 17px;
  }
    .section-two-btn {
      width: 200px;
    }

    .previous-btn {
      width: 180px;
    }

    .image-upload {
      padding-bottom: 47px;
    }
      
    .location-wrapper {
      margin-top: 20px;
    }

    .location-div {
      width: 187px;
  }

  .location {
    width: 187px;
  }

  .form-date-time {
    padding-bottom: 20px;
}
    
.reg-fee-div{      
  width: 200px;
}
.reg-fee{      
  width: 200px;
}

}


