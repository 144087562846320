.dashboard-navbar {
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 34px;
    padding-top: 20px;
  }
  
  .dashboard-navbar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 201px;
    height: 42px;
  }  
  
  /* For screen with max-width 768px */
  
  @media screen and (max-width: 768px)  {
  
    .dashboard-navbar {
      padding-right: 8px;
  }
  
    .dashboard-navbar-container {
      align-items:flex-start;  
      justify-content: end;
      padding: 0px;
      gap: 170px;
      width: 10vw;
    }
  }
  