.dashboard-body {
    background-color: black;
    height: 100vh;
    
}

@media screen and (max-width: 768px) {
    .dashboard-body {
        padding: 20px;
    }
    
}