.tournament-info {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 83px 50px;
    /* background: url('https://wallpaperaccess.com/full/183872.jpg'); */
    background-repeat: no-repeat;
    background-size: cover; 
    background-color: black;   
}

.main_tournament_details_container {
  display: flex;
  flex-direction: row;
  gap: 50px;
  width: 100%;

}

.first__section {
  display: flex;
  flex-direction: column;
  gap: 70px;
}

.games__list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 18px;  
  padding-bottom: 20px; 
  width: 660px;
}

.games__list > ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;    
  width: 100%;
  /* height: 150px; */
}

.games__list iframe {
  max-width: 100%; 
  max-height: 315px;
  margin: 0 auto;
}


.games__list > ul > li {
  list-style: none; 
}


.container-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
}

.item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 0px;
}

.more-like-this-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 9px;
    height: 32px;    
}

.permalink-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  height: 32px;
  gap: 9px;
}

.message {
  color: white;
  width: auto;
}

.span-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 28px;    
    color: #FFFFFF;
    text-transform: uppercase;
}

.icon_tournament {
    width: 20px;
    height: 20px;
    color: white;
    stroke: #FFFFFF;
  }

  path{
    stroke: white;    
}
.dash {
  rotate: 90deg;
}
 
  .tournaments-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 80px;    
  }

  .title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 5px;
  }

  .title-wrapper > h1 {    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 72px;    
    color: #FFFFFF;
    text-transform: capitalize;
  }

  .title-wrapper > p {
    width: 540px;    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;    
    color: #FFFFFF;
  }

  .first-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px;
  }

  .first-item-h {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;    
    color: #FFFFFF;
    text-transform: uppercase;
  }

  .location-container-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  .location-container-wrapper > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;    
    color: rgb(175, 175, 175);
    text-transform: capitalize;
  }

  .prize-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 10px;   
    padding-top: 20px;
  }

  .prize-wrapper > h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 40px;    
    color: #FFFFFF;
    text-transform: capitalize;
  }

  .prize-wrapper > h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 40px;
    color: #FFFFFF;
    text-align: center;
    
  }

  .final-btn {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding-top: 0px;
  }

  .join-tournaments-btn {
    padding: 10px 14px;
    border: none;    
    border-radius: 4px;
    background: #FF0000;    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: white;
    text-transform: uppercase;
    margin-top: 20px;
    margin-right: 20px;
  }

  .live-btn {
    padding: 8px 14px;
    background: none;
    border: solid;
    border-color: red;
    border-width: 2px;
  }

  .not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100wv;
    height: 100vh;
  }

  .not-found > h4 {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 72px;
    text-align: center;    
    color: black;
  }

  .tournament-img-container {
    width: 100%;
    /* height: 100%; */
    overflow: hidden;
  }

  .tournament-image {
    width: 100%;
    height: 582px;
    object-fit: cover;
  }
  
  
.h-username {
  color: white;
}

.mobile-img {
  display: none;
}

.desktop-img {
  display: flex;
}


.join-update-button {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
}



  @media (max-width: 768px) {

    .tournament-info {
      width: 100vw;
      height: 1570px;
      padding: 20px;
    }

    .container-wrap {
      gap: 15px;
    }

    .main_tournament_details_container {
      flex-direction: column;
  }

    .item-container {
      gap: 7px;
    }

    .icon_tournament {
      width: 16px;
      height: 16px;
    }

    .span-text {
      font-weight: 600;
      font-size: 13px;
      line-height: 22px;
    }

    .more-like-this-item {
      gap: 4px;
  }

    .permalink-item {
      gap: 4px;
  }
  .dash-div {
    display: none;
  }

  .location_div {
    margin-top: 14px;
  }

    .message {
      margin-top: 35px;
      margin-left: -175px;
      font-size: 11px;
    }

    .title-wrapper {
      gap: 23px;
    }

    .title-wrapper > h1 {
      font-size: 32px;
      line-height: 40px;      
    }

    .first-item-container {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 12px;
    }
    
    .location-container-wrapper { 
      height: 0px;
  }
    .dash {
      display: none;
    }

    .title-wrapper > p {
      width: 90vw;
      font-size: 16px;
      line-height: 23px;
    }

    .first-item-h {
      line-height: 3px;
  } 

    .tournament-img-container {
      width: 90vw;
      height: 450px; 
      overflow: visible ;
      margin-top: 30px;
    }

    .tournament-image {
      display: flex;
      width: 90vw;
      height: 450px;
    }

    .desktop-img {
      display: none;
    }

    .mobile-img {
      display: flex;
    }

    .prize-wrapper {
      padding-top: 10px;
    }

    .prize-wrapper > h2 {
      font-size: 26px;
    }

    .payment-details {
      line-height: 23px;

    }

    .final-btn {
      gap: 13px;
      padding-top: 0px;
    }

    .join-tournaments-btn {
      display: flex;
    }

    .games__list {
      width: 94vw;
      padding-top: 20px;
    }

    .games__list > ul {
      flex-direction: column;
      align-items: flex-start;
      width: 90vw;
    }

    .featured-text{
      font-size: 18px;
    }

  }
  