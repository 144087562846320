.game-details-container {
    display: flex;
    height: 320px;
}

.game-details-container > img {
    width: 850px;
    height: 320px;
}

.game-details-section-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 44px;
}

.game-details-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 85px;
    width: 850px;
}

.game__details__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;
    margin-left: -810px;
    margin-top: -44px;
}

.game__text__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;
}

.year {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px 6px;
    gap: 10px;
    
    width: 34px;
    height: 22px;
    
    background: linear-gradient(114.11deg, #231B10 3.05%, #C13509 97.98%);
    border-radius: 6px;
}

.year > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    line-height: 14px;
    color: #FFFFFF;
}

.text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
}

.text-wrapper > h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 45px;
    color: #FFFFFF;
}

.text-wrapper > p {
    width: 416px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 120%;
    color: #FFFFFF;
}

.icon-btn-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 18px;
    gap: 25px;
    
    width: 232px;
    height: 56px;
    
    background: linear-gradient(90.98deg, #071B24 2.6%, rgba(13, 32, 40, 0.987386) 23.43%, rgba(87, 87, 87, 0.838542) 95.65%);
    border-radius: 73px;
}

.earning-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 42px;
    background: #BD3D3D;
    border-radius: 73px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
}

.game-image {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
}


.see-tournaments {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 3px;
    height: 32px;
}

.see-tournaments-link {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 28px;
    text-decoration-line: underline;    
    color: #A72828;    
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.game-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 18px;    
    width: 660px;
    height: 100px;
}

.arrow-bck-right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;    
    background: #FF0000;
    border-radius: 20px;
}

.arrow-bck-left {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;    
    background: #FF0000;
    border-radius: 20px;
}

.game-list > ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;
    
    width: 550px;
    height: 100px;
}

.game-list > ul > li {
    list-style: none;
    overflow: hidden;
    width: 100%;
    height: 100%; 
}
.game-list > ul > li > img {
    width:100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width: 768px) {
    .game-details-section-wrapper {
        display: flex;
        padding: 0px;
        gap: 44px;
        width: 94vw;
    }

    .game-details-section {
        /* height: 850px; */
        display: flex;
        justify-content: center;
        align-items: center;
        width: 87vw;
        gap: 25px;
    }

    .game-details-container {
        /* width: 90vw; */
        height: 280px;
    }

    .game-details-container > img {
        width: 90vw;
        height: 280px;
    }

    .game__details__wrapper {
        display: flex;
        align-items: self-start;
        margin: 0;
    }

    .game__text__container {
        margin-left: -329px;
        margin-top: 20px;
        gap: 11px;
    }
    .text-wrapper > p {
        width: 300px;
    }

    .game-image {
        display: none;
    }

    .game-list {
        justify-content: center;
        gap: 5px;
        width: 100vw;
    }

    .game-list > ul {
        gap: 1px;
        width: 79vw;
    }

    .game-list > ul > li > img {
        width: 45px;
    }

    .arrow-bck-right {
        width: 24px;
        height: 24px;
    }

    .arrow-bck-left {
        width: 24px;
        height: 24px;
    }
    
}


@media only screen and (min-width: 412px) and (max-width: 414px) {

    .game__details__wrapper {
        display: flex;
        align-items: self-start;
        margin: 0;
    }

    .game__text__container {
        margin-left: -382px;
        margin-top: 20px;
    }
    .text-wrapper > p {
        width: 328px;
    }

    .game-details-container {
        height: 263px;
    }

    .game-details-container > img {
        height: 263px;
    }
    .game-list > ul > li > img {
        width: 53px;
    }
    
}