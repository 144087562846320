.tournaments-navbar{
  background: #0F0E17;
  height: 85px;
  border-bottom: 1px solid #ff0000;
  display: flex;
  align-items: center;
  justify-content: center;


  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw; */
}

.tournaments-navbar-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 824px;
  height: 42px;
  width: 1213px;
    /* display: flex;
    align-items: center;
    height: 80px;
    background: #1D1E22;
    border-bottom: 1px solid #FF3131; */
}

.tournaments-navbar-logo {
  width: 162px;
  height: 42px;
  cursor: pointer;
  display: flex;
  }

  .create-tournaments-link {
    text-decoration: none;
  }

  .create-tournaments-btn { 
    width: 208px;
    height: 48px;
    border: 1px solid #FF3131;
    background-color: #1D1E22;
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #FF3131;
    text-align: center;
    margin-right: 19px;
  }

  @media screen and (max-width: 768px) {
    .tournaments-navbar{
      width: 100vw;
  }

    .tournaments-navbar-container {
      display: flex;
      gap: 31px;
    }

  .create-tournaments-btn {
    display: block;
    width: 149px;
    height: 39px;
    font-size: 14px;
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 500;
}

   
  }


