/* VideoPopup.css */
.video-popup-container {
    display: inline-block;
    position: relative;
  }
  
  .video-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .video-content {
    padding: 20px;
    border-radius: 10px;
    position: relative;
  }
  
  .close-btn {
    position: absolute;
    top: -60px;
    right: 10px;
    font-size: 60px;
    color: red;
    cursor: pointer;
  }

  .youtube-video {
    width: 560px;
    height: 314px;
  }

  @media screen and (max-width: 768px) {
    .close-btn {
      top: -48px;
      right: 127px;
    }

    .youtube-video {
      width: 100%;
      height: 100%;
    }
    
  }
  