.potential-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #3E435E;
    height: 470px;
}

.potential-frame {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 44px;
    height: 442px;
}

.potential-frame-img {
    width: 560px;
    height: 320px;
}

.text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;    
    height: 406px;
}

.text-container-section-one {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 13px;    
    height: 176px;
}

.text-container-section-one >h2{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 24px;    
    letter-spacing: 1.5px;
    text-transform: uppercase;    
    color: #FF0000;
}

.text-container-section-one > p {
    width: 637px;    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1.5px;    
    color: #FFFFFF;
}

.text-container-section-two {
    width: 652px;
    height: 208px;    
    background: #313131;
    border-radius: 10px;
}

.ul-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 15px;
    /* width: 620px; */
    height: 173px;
    margin-top: 17px;
    margin-left: 16px;
}

.section-two-items-register {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 316px;    
    /* width: 620px; */
    height: 32px;
}

.register-title-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;    
    width: 273px;
    height: 32px;   
}

.icon-img {
    width: 32px;
    height: 32px;    
}

.register-title {
    width: 240px;    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;    
    text-align: justify;
    letter-spacing: 1.5px;    
    color: #FFFFFF;
}

.arrow {
    width: 32px;
    height: 32px;
}

.section-two-items-find {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 368px;    
    /* width: 620px; */
    height: 32px;
}

.find-title-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;
    
    width: 223px;
    height: 32px;
}

.find-title {
    width: 180px;    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: justify;
    letter-spacing: 1.5px;
    
    color: #FFFFFF;
}

.section-two-items-create {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 202px;
    
    /* width: 620px; */
    height: 32px;
}

.create-title-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;
    
    width: 392px;
    height: 32px; 
}

.create-title{
    width: 346px;
    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: justify;
    letter-spacing: 1.5px;
    
    color: #FFFFFF;
}

.section-two-items-rewards {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 222px;
    
    /* width: 620px; */
    height: 32px;
}

.rewards-title-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;
    
    width: 371px;
    height: 32px;
}

.rewards-title {
    width: 326px;    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: justify;
    letter-spacing: 1.5px;    
    color: #FFFFFF;
}

@media (max-width: 768px) {
    .potential-container {
        width: 100vw;
        height: 820px;
        align-items: flex-start;

    }

    .potential-frame {
        flex-direction: column;
        gap: 44px;
        height: 728px;
        width: 100%;
    }

    .potential-frame-img {
        width: 100%;
        height: 215px;
    }

    .text-container {
        height: 522px;
    }

    .text-container-section-one {
        height: 312px;
        margin-left: 10px;
    }

    .text-container-section-one >h2{
        font-size: 30px;
        line-height: 36px; 
    }

    .text-container-section-one > p {
        width: 350px;
        font-size: 14px;
    }

    .text-container-section-two {
        width: 100%;
        height: 218px;
    }

    .ul-text {
        margin-left: 0;
        height: 190px;
        margin-top: 1px;
        /* gap: 10px; */
    }

    .section-two-items-register {
        gap: 100px;
    }

    .section-two-items-create {
        gap: 126px;
    }

    .section-two-items-find {
        gap: 100px;
    }

    .section-two-items-rewards {
        gap: 131px;
        margin-top: 5px;
    }

    .register-title-container {
        width: 219px;
    }

    .register-title {
        width: 181px;
        font-size: 15px;
        line-height: 24px;
    }

    .create-title {
        width: 151px;
        font-size: 15px;
        line-height: 24px;
    }

    .find-title {
        width: 181px;
        font-size: 15px;
        line-height: 24px;
    }

    .rewards-title {
        width: 154px;
        font-size: 15px;
        line-height: 24px;
        text-align: start;
    }


    .icon-img {
        width: 28px;
        height: 28px;
    }

    .arrow {
        width: 28px;
        height: 28px;
    }
    
    .create-title-container {
        width: 198px;
    }

    .rewards-title-container {
        width: 195px;
        height: 37px;
    }
    
}