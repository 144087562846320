.tournaments-hero-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center;   */
    padding: 45px 88px;
    gap: 10px;
    /* height: 869px; */
    height: 520px;    
    background-image: url('../../public/images/tournaments-hero-section.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.tournaments-hero-title {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;    
    padding: 0px;
    gap: 35px;
    height: 393px;
}

.main-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
}

.tournaments-main-logo {
    width: 400px;
    height: 100px;
}

.hero-title-para {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;   
    text-transform: uppercase;
}

.hero-title-para > h1 {
    width: 1041px;    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 101px;
    line-height: 152px;  
    letter-spacing: 4px;    
    color: #FFFFFF;
}

.hero-title-para > p{
    width: 908px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #FFFFFF;
}

  .host-tournaments-link  {
    text-decoration: none;
  }

  .host-tournaments-btn {
    padding: 15px 56px;    
    background: #FF0000;
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;    
    letter-spacing: 2px;    
    color: #000000;
    border: none;
  }

  @media screen and (max-width: 768px) {

    .tournaments-hero-section {
        height: 380px;    
        width: 100vw
    }
    /* .img-hero-section {
        height: 310px;
    } */

    .tournaments-hero-title {
        gap: 25px;
    }
    
    .tournaments-main-logo {
        width: 260px;
        height: 60px;
    }
    
    .tournaments-main-logo > img {
        width: 260px;
    }

    .main-heading {
        gap: 20px;
    }

    .hero-title-para {
        gap: 10px;
    }
    
    .hero-title-para > h1 {
        width: 350px;    
        font-size: 30px; 
        line-height: 37px;
        text-align: center;
        }
    
    .hero-title-para > p {
        width: 350px;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        text-transform: none;
    }

    .host-tournaments-btn {
        display: block;
    }
}