.profile-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    height: 116vh;
    gap: 50px;
    padding-bottom: 50px;
}

.profile-picture-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-top: 40px;
}

.profile-picture {
    width: 300px;
    border-radius: 150px;
}

.password-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.password-section > h2 {
    color: white;
    font-family: 'Poppins';
}

@media screen and (max-width: 768px) {
    .profile-page-container {
        padding-top: 25px;
        padding-bottom: 25px;
        height: fit-content;
    }
    
}

@media screen and (min-width: 390px) and (max-width: 414px) {
    .profile-page-container {
        padding-top: 0px;
        padding-bottom: 0px;
        height: 100vh;
    }
    
}